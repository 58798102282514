import http from "../../utils/http"

/**
 * 工单列表
 * @param {object} params
 */
export function workOrderList(params) {
    return http({
        url: "/api/workorder/lists",
        data: params,
        forceLogin: true
    })
}

/**
 * 取消工单
 * @param {object} params
 */
export function cancelWorkOrder(params) {
    return http({
        url: "/api/workorder/cancel",
        data: params,
        forceLogin: true
    })
}

/**
 * 删除工单
 * @param {object} params
 */
export function deleteWorkOrder(params) {
    return http({
        url: "/api/workorder/delete",
        data: params,
        forceLogin: true
    })
}

/**
 * 评价工单
 * @param {object} params
 */
export function evaluateWorkOrder(params) {
    return http({
        url: "/api/workorder/evaluate",
        data: params,
        forceLogin: true
    })
}

/**
 * 工单详情
 * @param {object} params
 */
export function workOrderDetail(params) {
    return http({
        url: "/api/workorder/detail",
        data: params,
        forceLogin: true
    })
}

/**
 * 修改上门地址
 * @param {object} params
 */
export function modifyAddress(params) {
    return http({
        url: "/api/Workorder/modifyAddress",
        data: params,
        forceLogin: true
    })
}

/**
 * 修改上门时间
 * @param {object} params
 */
export function modifyAppointTime(params) {
    return http({
        url: "/api/Workorder/modifyAppointTime",
        data: params,
        forceLogin: true
    })
}
/**
 * 创建工单
 * @param {object} params
 */
export function createWorkOrder(params) {
    return http({
        url: "/api/workorder/create",
        data: params,
        forceLogin: true
    })
}

/**
 * 工单创建数据
 * @param {object} params
 */
export function workOrderCreateData(params) {
    return http({
        url: "/api/workorder/createData",
        data: params,
        forceLogin: true
    })
}


/**
 * 默认地址
 * @param {object} params
 */
export function defaultAddress(params) {
    return http({
        url: "/api/Memberaddress/info",
        data: params,
        forceLogin: true
    })
}


/**
 * 服务卡列表
 * @param {object} params
 */
export function cardList(params) {
    return http({
        url: "/api/servicecard/page",
        data: params,
        forceLogin: true
    })
}