<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>
		
		<div v-loading="loading">
			<el-card class="box-card order-list">
				<div slot="header" class="clearfix">
					<el-breadcrumb separator="/">
						<el-breadcrumb-item>{{work_type == 1 ? '安装服务' : '维修服务'}}</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<!--商品信息-->
				<div class="goods-list">
					<table>
						<tr>
							<td width="62.5%">商品</td>
							<td width="12.5%">数量</td>
							<td width="12.5%">金额</td>
						</tr>
					</table>
				</div>

				<div class="goods-list">
					<table>
						<tr>
							<td width="62.5%">
								<div class="goods-info">
									<div class="goods-info-left">
										<router-link :to="{ path: '/sku-' + order_goods_info.sku_id }" target="_blank">
											<img
												class="goods-img"
												:src="$img(order_goods_info.sku_image, { size: 'mid' })"
												@error="order_goods_info.sku_image = defaultGoodsImage"
											/>
										</router-link>
									</div>
									<div class="goods-info-right">
										<router-link :to="{ path: '/sku-' + order_goods_info.sku_id }" target="_blank">
											<div class="goods-name">{{ order_goods_info.sku_name }}</div>
										</router-link>
									</div>
								</div>
							</td>
							<td width="12.5%" class="goods-num">{{ order_goods_info.num }}</td>
							<td width="12.5%" class="goods-money">￥{{ order_goods_info.goods_money }}</td>
						</tr>
					</table>
				</div>
			</el-card>
			
			<!--退款填写-->
			<div class="item-block">
				<div class="block-text"></div>
			
				<el-form ref="form" label-width="80px" class="refund-form">
					<el-form-item :label="work_type==1?'安装要求':'维修要求'">
						<el-input rows="5" :placeholder="work_type ==1? '请描述安装服务的具体要求（必填）':'请描述维修服务的具体要求（必填）'" type="textarea" v-model="work_desc" ></el-input>
					</el-form-item>
					<el-form-item label="要求图片" class="require_img">
						<el-upload
							ref="upload"
							:class="{ ishide: hide }"
							:action="uploadActionUrl"
							list-type="picture-card"
							:on-success="
								(file, fileList) => {
									return handleSuccess(file, fileList);
								}
							"
							:on-preview="handlePictureCardPreview"
							:on-remove="
								(file, fileList) => {
									return handleRemove(file, fileList);
								}
							"
							:on-exceed="handleExceed"
						>
							<i class="el-icon-plus"></i>
						</el-upload>
						<el-dialog :visible.sync="dialogVisible"><img width="100%" :src="dialogImageUrl" alt="" /></el-dialog>
						<span>共5张，还能上传{{ imgList.length ? 5 - imgList.length : 5 }}张</span>
					</el-form-item>
					<el-form-item label="上门地址" class="work_address">
						<div class="text item ns-add-address" v-if="!default_address" @click="toAddressList()" >
							<span>请选择上门地址</span>
						</div>
						<div class="text item" v-else>
							<div class="text-name">
								<span>{{ default_address.name }}</span>
							</div>
						
							<div class="text-content">
								<p>{{ default_address.mobile }}</p>
								<p :title="default_address.full_address + default_address.address" class="ns-address-detail">{{ default_address.full_address }}{{ default_address.address }}</p>
							</div>
						
							<div class="text-operation">
								<span @click="toAddressList()">更多地址 <i class="el-icon-arrow-right"></i> </span>
							</div>
						</div>
					</el-form-item>
					<el-form-item label="上门时间" class="time">
						<el-date-picker
							class="worker_day"
							v-model="worker_day"
							type="date"
							placeholder="选择日期"
							value-format="yyyy-MM-dd"
							:picker-options="pickerOptions0"
							@change="changeWorkerDay">
						</el-date-picker>
						<el-time-picker
							ref="timePicker"
						    is-range
						    v-model="worker_time"
							value-format="HH:mm"
							format="HH:mm"
						    range-separator="至"
						    start-placeholder="开始时间"
						    end-placeholder="结束时间"
						    placeholder="选择时间范围"
							>
						  </el-time-picker>
					</el-form-item>
				</el-form>
			</div>

			<div class="item-block">
				<div class="order-submit"><el-button type="primary" class="el-button--primary" @click="commitWorkOrder()" >提交工单</el-button></div>
				<div class="clear"></div>
			</div>
		</div>
	</div>
</template>

<script>
import Config from '@/utils/config';
import { mapGetters } from 'vuex';
import { workOrderCreateData ,defaultAddress ,createWorkOrder } from '@/api/order/work_order'
export default {
	name: 'refund',
	components: {},
	data: () => {
		return {
			order_goods_id: '',
			work_type:1,
			loading: true,
			yes: true,
			order_goods_info:{},
			work_desc:'',//安装要求
			imgList: [],//要求图片
			uploadActionUrl: Config.baseUrl + '/api/upload/evaluateimg',
			hide: '',
			dialogVisible: false,
			dialogImageUrl: '',
			default_address:null,
			member_address_id:'',
			worker_day:'',//上门日期
			worker_time:'',//上门时间段
			pickerOptions0: {
				disabledDate(time) {
					return time.getTime() < Date.now() - 8.64e7;//选择今天以及今天之后的日期
				}
			}, 
		};
	},
	created() {
		if (this.$route.query.order_goods_id) this.order_goods_id = this.$route.query.order_goods_id;
		if(this.$route.query.work_type) this.work_type = this.$route.query.work_type;
		this.getCreateData();
		this.getDefaultAddress();
	},
	computed: {
		...mapGetters(['defaultGoodsImage'])
	},
	mounted() {
		let self = this;
		setTimeout(function() {
			self.yes = false
		}, 300)
	},
	methods: {
		commitWorkOrder(){
			var work_time =''
			if(this.worker_time){
				work_time = this.worker_time[0]+'-'+this.worker_time[1];
			}
			if(!this.work_desc){
				if(this.work_type==1){
					this.$message.error('请输入安装要求')
				}else{
					this.$message.error('请输入维修要求')
				}
				return;
			}
			if(!this.member_address_id){
				this.$message.error('请选择上门地址')
				return;
			}
			if(!this.worker_day || !work_time){
				this.$message.error('请选择上门时间')
				return;
			}
			//检测选中日期是不是当天
			let todayDate=new Date().setHours(0,0,0,0);
			let paramsDate=new Date(this.worker_day).setHours(0,0,0,0);
			let isToday=(todayDate===paramsDate);
			if(isToday){
				//如果是当天  检测开始时间是不是当前时间之后
				if(this.worker_time[0].split(':')[0] < new Date().getHours() || ( ( this.worker_time[0].split(':')[0] == new Date().getHours() ) && this.worker_time[0].split(':')[1] <= new Date().getMinutes() ) ){
					this.$message.error('预约时间不可早于当前时间');
					return;
				}
			}
			createWorkOrder({
				work_type:this.work_type,
				order_goods_id:this.order_goods_id,
				work_desc:this.work_desc,
				apply_images:this.imgList.join(','),
				member_address_id:this.member_address_id,
				appoint_day:this.worker_day,
				appoint_time:work_time
			})
			.then(res=>{
				if(res.code>=0){
					this.$message('提交成功');
					setTimeout(()=>{
						this.$router.push({
							path:'/member/work_order_list'
						})
					},500)
				}
			})
			.catch(err=>{})
		},
		
		changeWorkerDay(e){
			this.$refs.timePicker.focus()
		},
		toAddressList(){
			this.$router.push({
				path:'/member/delivery_address',
			})
		},
		getDefaultAddress(){
			defaultAddress({
				default:1
			})
			.then(res=>{
				if (res.code >= 0) {
					this.default_address = res.data;
					this.member_address_id = res.data.id;
				} else {
					
				}
			})
			.catch(err=>{})
		},
		handlePictureCardPreview(file) {
			// 点开大图
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
		},
		handleExceed(file, fileList) {
			// 图片数量大于6
			this.$message.warning('上传图片最大数量为5张');
		},
		handleSuccess(file, fileList) {
			let arr = this.imgList;
			arr = arr.concat(file.data.pic_path);
			this.imgList = [];
			this.imgList = arr;
			if (this.imgList.length >= 5) {
				this.hide = true;
			}
		},
		handleRemove(file, fileList) {
			let i = util.inArray(file.response.data.pic_path, this.imgList);
			this.imgList.splice(i, 1);
		
			if (this.imgList.length < 5) {
				this.hide = false;
			}
		},
		getCreateData(){
			workOrderCreateData({
				order_goods_id:this.order_goods_id
			})
			.then(res=>{
				if(res.code>=0){
					this.order_goods_info = res.data.order_goods_info;
					this.loading = false;
				}else{
					this.$message.error(res.message);
				}
			})
			.catch(err=>{
				this.$message.error(err.message)
			})
		},
	}
};
</script>
<style lang="scss">
	.refund-form {
		.el-upload--picture-card,
		.el-upload-list--picture-card .el-upload-list__item {
			width: 70px;
			height: 70px;
			line-height: 80px;
			position: relative;
		}
		.el-upload-list--picture-card .el-upload-list__item-thumbnail {
			width: 100%;
			height: auto;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		.el-upload-list__item.is-success .el-upload-list__item-status-label {
			display: none;
		}
		.ishide .el-upload--picture-card {
			display: none;
		}
		.el-dialog {
			.el-dialog__body {
				text-align: center;
			}
		}
	}
.time .el-form-item__content{
	width: auto !important;
}
.el-form-item__content{
	width: 35%;
}
.require_img .el-form-item__content{
	width: 45% !important;
}
.worker_day{
	margin-right: 30px;
}
</style>
<style lang="scss" scoped>

.work_address{
	.text {
	    height: 140px;
	    border-radius: 5px;
	    border: 1px solid #d8d8d8;
	    margin-bottom: 20px;
	    padding: 0 15px;
	    box-sizing: border-box;
	
	    .text-name {
	        height: 37px;
	        line-height: 40px;
	        padding: 0 10px;
	        border-bottom: 1px solid #eeeeee;
	    }
	
	    .text-default {
	        display: inline-block;
	        margin-left: 10px;
	        background: $base-color;
	        color: #ffffff;
	        width: 35px;
	        height: 20px;
	        line-height: 20px;
	        text-align: center;
	        border-radius: 3px;
	    }
	
	    .text-content {
	        padding: 10px;
			p{
				line-height: 1.8;
			}
	    }
	
	    .ns-address-detail {
	        overflow: hidden;
	        text-overflow: ellipsis;
	        white-space: nowrap;
	    }
	
	    .text-operation {
	        // 操作
	        text-align: right;
			line-height: 1.8;
	        span {
	            margin: 0 5px;
	            color: #999999;
	            cursor: pointer;
				i{
					vertical-align: middle;
				}
	        }
	
	        span:hover {
	            color: $base-color;
	        }
	    }
	}
	.ns-add-address {
	    border: 1px dashed #d8d8d8;
	    text-align: center;
	    color: #999999;
	    line-height: 140px;
	    cursor: pointer;
	}
}
.box {
	width: 100%;
	position: relative;
}

.null-page {
	width: 100%;
	height: 730px;
	background-color: #FFFFFF;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9;
}

.el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
	box-shadow: unset;
}

.el-card {
	border: 0;
}
.clear {
	clear: both;
}
.item-block {
	padding: 0 15px 1px;
	margin: 10px 0;
	border-radius: 0;
	border: none;
	background: #ffffff;
	.block-text {
		border-color: #eeeeee;
		color: $ns-text-color-black;
		padding: 7px 0;
		border-bottom: 1px;
	}
}
.refund-form {
	.el-select {
		width: 100%;
	}
}
.order-submit {
	text-align: center;
	padding: 10px;
}
.goods-list {
	padding: 15px 0;
	table {
		width: 100%;
	}
	.goods-info-left {
		width: 60px;
		height: 60px;
		float: left;
		.goods-img {
			width: 60px;
			height: 60px;
		}
	}
	.goods-info-right {
		float: left;
		height: 60px;
		margin-left: 10px;
		color: $base-color;
		width: 80%;
		.goods-name {
			line-height: 20px;
			padding-top: 10px;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
		}
		.goods-spec {
			color: #999;
		}
	}
}
.pay-type-list {
	padding: 20px 0;
}
.pay-type-item {
	display: inline-block;
	border: 2px solid #eeeeee;
	padding: 5px 20px;
	margin-right: 20px;
	cursor: pointer;
}
.pay-type-item.active {
	border-color: $base-color;
}
.status-wrap {
	color: #999;
}
.media-left {
	float: left;
}
.media-right {
	float: right;
	i.rotate {
		transform: rotate(180deg);
		transition: 0.3s;
	}
}
.action-box {
	padding: 10px 0;
}
.action-way {
	float: left;
	color: #999;
}
.head .time {
	float: right;
	color: #999;
}
.record-item {
	margin-bottom: 10px;
}
.order-statistics {
	float: left;
	padding: 10px;
	// color: #999;
}
.el-textarea .el-input__count {
	line-height: 20px;
}
</style>
